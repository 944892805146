import { GatsbySeo } from 'gatsby-plugin-next-seo'
import NewAboutUs from 'src/components/Institucional/NewAboutUs'

function AboutDecathlon() {
  return (
    <>
      <GatsbySeo />
      <NewAboutUs />
    </>
  )
}

export default AboutDecathlon
